import { DATE_DELIMITER } from '../config';

import { I18nService } from '~shared/lib/i18n';
import { getDeclensionWord } from '~shared/lib';

export const addZeroPrefix = (value: number | string) => {
  return value?.toString().length < 2 ? '0' + value : value;
};

export const ISOStringToDateAppFormat = (date: string | Date) => {
  const _date = new Date(date ?? new Date());

  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const day = _date.getDate();

  return `${addZeroPrefix(day)}${DATE_DELIMITER}${addZeroPrefix(month)}${DATE_DELIMITER}${year
    .toString()
    .slice(2)}`;
};

export const getMonthPeriod = (date?: string) => {
  const currentDate = new Date(date ?? new Date());
  const startDate = new Date(currentDate);
  startDate.setDate(1);

  return [
    ISOStringToDateAppFormat(startDate.toString()),
    ISOStringToDateAppFormat(currentDate.toISOString()),
  ];
};

type Options = {
  withTime?: boolean;
};

export const getCurrentDate = (options?: Options): string => {
  const year = getCurrentYear();
  const month = getCurrentMonth();
  const day = getCurrentDay();
  const hours = getCurrentHours();
  const minutes = getCurrentMinutes();

  return options?.withTime
    ? `${day}.${month}.${year} ${hours}:${minutes}`
    : `${day}.${month}.${year}`;
};

export const getCurrentYear = (): string => {
  return new Date().getFullYear().toString().slice(2);
};

export const getCurrentMonth = (): string | number => {
  return addZeroPrefix(new Date().getMonth() + 1);
};

export const getCurrentDay = (): string | number => {
  return addZeroPrefix(new Date().getDate());
};

export const getCurrentHours = (): string | number => {
  return addZeroPrefix(new Date().getHours());
};

export const getCurrentMinutes = (): string | number => {
  return addZeroPrefix(new Date().getMinutes());
};

export const convertDateToAppFormat = (
  dateStr?: string | null,
  params?: { withTime?: boolean; shortYear?: boolean; onlyTime?: boolean }
) => {
  if (!dateStr) return undefined;

  const date = new Date(dateStr).toLocaleString('ru', {
    year: params?.shortYear ? '2-digit' : 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const time = new Date(dateStr).toLocaleString('ru', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Asia/Tashkent',
  });

  return params?.onlyTime ? time : `${date}${params?.withTime ? ` ${time}` : ''}`;
};

export const getDatesInInterval = (startDate: string, endDate: string, interval: number) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const currentDate = start;

  const result = [];

  while (currentDate <= end) {
    result.push(new Date(currentDate).toISOString().substring(0, 10));
    currentDate.setDate(currentDate.getDate() + interval + 1);
  }

  return result;
};

export const getDateWithoutTime = (date: string | undefined | null) => {
  if (!date) {
    return '-';
  }

  return date.split(' ')[0] ?? '-';
};

export const convertIsoStringToApiFormat = (dateStr?: string | null) => {
  if (!dateStr) return '';

  return new Date(dateStr)
    .toLocaleString('ru', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Asia/Tashkent',
    })
    .split('.')
    .reverse()
    .join('-');
};

export const convertOldApiFormatToNewApiFormat = <T = string>(dateStr: T) => {
  if (typeof dateStr !== 'string') {
    return dateStr;
  } else {
    const [day, month, year] = dateStr.split('.');

    const date = new Date(`${month}.${day}.${year}`).toLocaleString('ru', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Asia/Tashkent',
    });

    return date.split('.').reverse().join('-') as T;
  }
};

export const calculateAge = (dateStr: string) => {
  const birthDate = new Date(dateStr);
  const birthdateTimeStamp = birthDate.getTime();
  const diff = Date.now() - birthdateTimeStamp;
  // This is the difference in milliseconds
  let age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  if (age > 0) {
    return `${age} ${getDeclensionWord(age, [
      I18nService.t('Common.YearWithDeclension'),
      I18nService.t('Common.YearWithDeclension2_4'),
      I18nService.t('Common.YearWithDeclension5+'),
    ]).toLowerCase()}`;
  } else {
    const today = new Date();
    age =
      (today.getFullYear() - birthDate.getFullYear()) * 12 +
      (today.getMonth() - birthDate.getMonth());
    return `${age} ${I18nService.t('Common.MonthShort').toLowerCase()}`;
  }
};

export const getCurrentDateInApiFormat = (daysToAdd = 0) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + daysToAdd);

  const formattedDate = currentDate.toLocaleString('ru', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Asia/Tashkent',
  });

  return formattedDate.split('.').reverse().join('-');
};

/**
 * @param {string} date format - DD.MM.YY hh:mm
 */
export const excludeDate = (date: string) => {
  return date.split(' ')[1];
};
/**
 * @param {string} date format - DD.MM.YY hh:mm
 */
export const excludeTime = (date: string) => {
  return date.split(' ')[0];
};

export const getBirthdateInDateFormat = (startDate: string | undefined | null) => {
  if (!startDate) return '';

  const splitDate = startDate.split('.');
  return splitDate.reverse().join('-');
};

export const getLastMonthPeriod = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate);
  fromDate.setMonth(toDate.getMonth() - 1);

  return [
    convertIsoStringToApiFormat(fromDate.toISOString()),
    convertIsoStringToApiFormat(toDate.toISOString()),
  ];
};

export const getMonthPeriodApiFormat = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate);
  fromDate.setDate(1);
  return [
    convertIsoStringToApiFormat(fromDate.toISOString()),
    convertIsoStringToApiFormat(toDate.toISOString()),
  ];
};

export const convertShortYearDate = (dateStr: string | undefined): string | undefined => {
  if (!dateStr) return undefined;
  // Format: dd.mm.yy;
  // Разбиваем строку на день, месяц и год
  const [day, month, year] = dateStr.split('.');

  // Преобразуем короткий год (гг) в полный (гггг)
  const fullYear = +(year || 0) < 50 ? '20' + year : '19' + year;

  // Возвращаем дату в формате дд.мм.гггг
  return `${day}.${month}.${fullYear}`;
};
