// TODO: нужно рефакторить
import { createRouter, createWebHistory, RouteRecordRaw, RouteComponent } from 'vue-router';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { REDIRECT_ROUTE } from '~pages/Redirect';
import {
  SETTINGS_LIST_ROUTE,
  SETTINGS_ORGANIZATIONS_TYPE_ROUTE,
  SETTINGS_ORGANIZATIONS_ROUTE,
  SETTINGS_POSITIONS_ROUTE,
} from '~pages/settings';
import {
  APPOINTMENT_ROUTE,
  APPOINTMENT_SCHEDULE_ROUTE,
  APPOINTMENTS_ACTIVE_ROUTE,
  APPOINTMENTS_PROVIDED_ROUTE,
  APPOINTMENTS_CANCELED_ROUTE,
  MEDICAL_CASE_ROUTE,
  UNSCHEDULED_MEDICAL_CASE_ROUTE,
  APPOINTMENT_CREATE_ROUTE,
  EXAMINATION_PLAN_APPOINTMENT_ROUTE,
  WRITE_OUT_RECIPE,
} from '~pages/appointments';
import { CHECKUP_TEMPLATE_HEADER_ROUTE } from '~pages/CheckupHeaderTemplate';
import { CHECKUP_TEMPLATE_HEADER_TABLE_ROUTE } from '~pages/CheckupHeaderTemplateTable';
import { CHECKUP_TEMPLATE_EDITOR_ROUTE } from '~pages/CheckupTemplateEditor';
import { CHECKUP_TEMPLATES_ROUTE } from '~pages/CheckupTemplates';
import { CONTINGENT_ARCHIVED_LIST_ROUTE, CONTINGENT_MAIN_LIST_ROUTE } from '~pages/contingent';
import { DEPOSIT_LIST_ROUTE } from '~pages/DepositList';
import { PATIENT_CHECKUP_ROUTE } from '~pages/PatientCheckup';
import { DIRECTOR_DASHBOARD_ROUTE } from '~pages/DirectorDashboard';
import { INPATIENT_CARE_LIST_ROUTE } from '~pages/InpatientCareList';
import { INVOICE_LIST_ROUTE } from '~pages/InvoiceList';
import { PATIENT_MEDICAL_CARD_ROUTE } from '~pages/PatientMedicalCard';
import { G2G_PATIENT_PROFILE_ROUTE } from '~pages/G2gPatientProfile';
import { B2G_PATIENT_PROFILE_ROUTE } from '~pages/B2gPatientProfile';
import { LOGIN_ROUTE } from '~pages/LoginPage';
import { NOT_FOUND_ROUTE } from '~pages/NotFound';
import { NO_ACCESS_ROUTE } from '~pages/NoAccess';
import { FORGOT_PASSWORD_ROUTE } from '~pages/ForgotPassword';
import { WAITING_CONFIRM_ROUTE } from '~pages/WaitingConfirm';
import {
  DISPENSARIZATION_MAIN_LIST_ROUTE,
  DISPENSARIZATION_PATIENT_LIST_ROUTE,
  DISPENSARIZATION_CODE_LIST_ROUTE,
  DISPENSARIZATION_DEREGISTERED_PATIENT_LIST_ROUTE,
  DISPENSARIZATION_NEW_PATIENT_LIST_ROUTE,
} from '~pages/analytics/dispensarization';
import { PATIENT_LIST_ROUTE } from '~pages/PatientList';
import { PHARMACY_ROUTE } from '~pages/Pharmacy';
import {
  DISABLED_PEOPLE_MAIN_LIST_ROUTE,
  DISABLED_PEOPLE_CODE_LIST_ROUTE,
  DISABLED_PEOPLE_PATIENT_TRANSFERRED_ROUTE,
} from '~pages/analytics/disabledPeople';
import { PAID_PATIENTS_LIST_ROUTE } from '~pages/PaidPatientList';
import { TREATMENT_COURSE_ROUTE } from '~pages/TreatmentCourse';
import {
  HOSPITALIZATION_LIST_ROUTE,
  HOSPITALIZATION_REFERRAL_ROUTE,
  EDIT_HOSPITALIZATION_REFERRAL_ROUTE,
  VIEWING_HOSPITALIZATION_REFERRAL_ROUTE,
  HOSPITALIZATION_REFERRAL_MEDICAL_CASE_ROUTE,
} from '~pages/hospitalization';
import {
  HEALTH_GROUP_CODE_LIST_ROUTE,
  HEALTH_GROUP_MAIN_LIST_ROUTE,
  HEALTH_GROUP_PATIENT_LIST_ROUTE,
} from '~pages/analytics/healthGroup';
import {
  VACCINATION_MAIN_LIST_ROUTE,
  VACCINATION_MICRODISTRICT_LIST_ROUTE,
  VACCINATION_GROUP_LIST_ROUTE,
} from '~pages/analytics/vaccination';
import {
  PREGNANCY_MAIN_LIST_ROUTE,
  PREGNANCY_MICRODISTRIC_LIST_ROUTE,
  PREGNANCY_GROUP_LIST_ROUTE,
} from '~pages/analytics/pregnancy';
import { DEMOGRAPHY_MAIN_LIST_ROUTE } from '~pages/analytics/demography';
import {
  PATRONAGE_PLANNING_LIST_ROUTE,
  PATRONAGE_LIST_ROUTE,
  PATRONAGE_PATIENT_ROUTE,
} from '~pages/patronage';
import { WOMEN_DASHBOARD_ROUTE } from '~pages/analytics/WomenDashboard';
import {
  SURVEY_MAIN_LIST_ROUTE,
  SURVEY_PATIENT_ROUTE,
  SURVEY_CODE_LIST_ROUTE,
} from '~pages/analytics/survey';
import { FERTILITY_ROUTE } from '~pages/analytics/Fertility';
import { ANALYTIC_DIRECTIONS_ROUTE } from '~pages//analytics/Directions';
import { ANALYTIC_BY_APPOINTMENTS_ROUTE } from '~pages/analytics/Appointments';
import { ATTACHED_POPULATION_ROUTE } from '~pages/analytics/AttachedPopulation';
import {
  STAFF_LIST_ROUTE,
  EMPLOYEE_PROFILE_ROUTE,
  EMPLOYEE_DOCTOR_LIST_ROUTE,
  EMPLOYEE_DOCTOR_PROFILE_ROUTE,
} from '~pages/users';
import {
  EMERGENCY_ROOM_LIST_ROUTE,
  EMERGENCY_ROOM_REFERRAL_ROUTE,
  EMERGENCY_ROOM_WITHOUT_REFERRAL_ROUTE,
  EMERGENCY_ROOM_PATIENT_CHECKUP_ROUTE,
  COVER_SHEET_FORM003_ROUTE,
  EMERGENCY_ROOM_PATIENT_CHECKUP_EDIT_ROUTE,
  EXAMINATION_PLAN_ROUTE,
  STATISTICAL_MAP_FORM066_ROUTE,
  SURGERY_EDIT_ROUTE,
  BIRTH_PROTOCOL_ROUTE,
} from '~pages/emergencyRoom';
import { EDIT_EPICRISIS_ROUTE, CREATE_EPICRISIS_ROUTE } from '~pages/Epicrisis';
import { STATISTIC_DASHBOARD_ROUTE } from '~pages/StatisticDashboard';
import { ICON_LIST_ROUTE } from '~pages/IconList';
import { ANALYSIS_LIST_ROUTE } from '~pages/AnalysisList';
import { DOCUMENT_ROUTE } from '~pages/Document';
import { SCREENINGS_CVD_ROUTE, SCREENINGS_ENDOCRINE_ROUTE } from '~pages/screenings';
import { REPORTS_ON_FORMS_ROUTE } from '~pages/ReportsOnForms';
import { RECIPE_LIST_ROUTE } from '~pages/RecipeList';
import { ROLE_SELECTION_ROUTE } from '~pages/RoleSelection';
import {
  ACCOUNTING_REFERRAL_ACCRUAL_LIST_ROUTE,
  ACCOUNTING_REFERRAL_SOURCE_LIST_ROUTE,
  REFERRAL_COMMISSION_LIST_ROUTE,
} from '~pages/accounting';
import { OAUTH_ROUTE } from '~pages/OAuth';
import { REIMBURSMENT_RECIPE_LIST_ROUTE } from '~pages/reimbursement/RecipeList';
import { REDIRECT_TO_BI_ROUTE } from '~pages/RedirectToBI';
import {
  EMERGENCY_ROOM_EXCHANGE_CARD_FORM113_ROUTE,
  MEDICAL_CASE_EXCHANGE_CARD_FORM113_ROUTE,
  MEDICAL_CASE_EXCHANGE_CARD_FORM113_EDIT_ROUTE,
  UNSCHEDULED_MEDICAL_CASE_EXCHANGE_CARD_FORM113_ROUTE,
  UNSCHEDULED_MEDICAL_CASE_EXCHANGE_CARD_FORM113_EDIT_ROUTE,
} from '~pages/form113';
import { useAppConfigStore, useSessionStore } from '~entities/session';
import { ROUTE_PATH } from '~shared/config';
import {
  onlyUserIsAuthMiddleware,
  I18nService,
  amplitudeService,
  insertComponentsInRoutes,
} from '~shared/lib';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter: [onlyUserIsAuthMiddleware, _redirectCurrentPageByUserRole],
    },
    APPOINTMENT_ROUTE,
    APPOINTMENT_CREATE_ROUTE,
    APPOINTMENTS_ACTIVE_ROUTE,
    EXAMINATION_PLAN_APPOINTMENT_ROUTE,
    WRITE_OUT_RECIPE,
    CONTINGENT_MAIN_LIST_ROUTE,
    CONTINGENT_ARCHIVED_LIST_ROUTE,
    G2G_PATIENT_PROFILE_ROUTE,
    B2G_PATIENT_PROFILE_ROUTE,
    CHECKUP_TEMPLATES_ROUTE,
    CHECKUP_TEMPLATE_EDITOR_ROUTE,
    CHECKUP_TEMPLATE_HEADER_ROUTE,
    CHECKUP_TEMPLATE_HEADER_TABLE_ROUTE,
    PATIENT_MEDICAL_CARD_ROUTE,
    PATIENT_CHECKUP_ROUTE,
    INPATIENT_CARE_LIST_ROUTE,
    INVOICE_LIST_ROUTE,
    DEPOSIT_LIST_ROUTE,
    DIRECTOR_DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    WAITING_CONFIRM_ROUTE,
    DISPENSARIZATION_MAIN_LIST_ROUTE,
    DISPENSARIZATION_PATIENT_LIST_ROUTE,
    DISPENSARIZATION_CODE_LIST_ROUTE,
    DISPENSARIZATION_DEREGISTERED_PATIENT_LIST_ROUTE,
    DISPENSARIZATION_NEW_PATIENT_LIST_ROUTE,
    PHARMACY_ROUTE,
    DISABLED_PEOPLE_MAIN_LIST_ROUTE,
    DISABLED_PEOPLE_CODE_LIST_ROUTE,
    DISABLED_PEOPLE_PATIENT_TRANSFERRED_ROUTE,
    SETTINGS_LIST_ROUTE,
    SETTINGS_ORGANIZATIONS_TYPE_ROUTE,
    SETTINGS_ORGANIZATIONS_ROUTE,
    SETTINGS_POSITIONS_ROUTE,
    PAID_PATIENTS_LIST_ROUTE,
    TREATMENT_COURSE_ROUTE,
    APPOINTMENT_SCHEDULE_ROUTE,
    HOSPITALIZATION_LIST_ROUTE,
    HOSPITALIZATION_REFERRAL_ROUTE,
    EDIT_HOSPITALIZATION_REFERRAL_ROUTE,
    VIEWING_HOSPITALIZATION_REFERRAL_ROUTE,
    HOSPITALIZATION_REFERRAL_MEDICAL_CASE_ROUTE,
    PATIENT_LIST_ROUTE,
    HEALTH_GROUP_MAIN_LIST_ROUTE,
    HEALTH_GROUP_PATIENT_LIST_ROUTE,
    HEALTH_GROUP_CODE_LIST_ROUTE,
    PREGNANCY_MAIN_LIST_ROUTE,
    PREGNANCY_MICRODISTRIC_LIST_ROUTE,
    PREGNANCY_GROUP_LIST_ROUTE,
    VACCINATION_MAIN_LIST_ROUTE,
    VACCINATION_MICRODISTRICT_LIST_ROUTE,
    VACCINATION_GROUP_LIST_ROUTE,
    DEMOGRAPHY_MAIN_LIST_ROUTE,
    NOT_FOUND_ROUTE,
    NO_ACCESS_ROUTE,
    APPOINTMENTS_PROVIDED_ROUTE,
    APPOINTMENTS_CANCELED_ROUTE,
    PATRONAGE_LIST_ROUTE,
    PATRONAGE_PLANNING_LIST_ROUTE,
    PATRONAGE_PATIENT_ROUTE,
    WOMEN_DASHBOARD_ROUTE,
    SURVEY_MAIN_LIST_ROUTE,
    SURVEY_PATIENT_ROUTE,
    SURVEY_CODE_LIST_ROUTE,
    FERTILITY_ROUTE,
    ANALYTIC_DIRECTIONS_ROUTE,
    STAFF_LIST_ROUTE,
    EMPLOYEE_DOCTOR_LIST_ROUTE,
    EMPLOYEE_DOCTOR_PROFILE_ROUTE,
    EMPLOYEE_PROFILE_ROUTE,
    EMERGENCY_ROOM_LIST_ROUTE,
    EMERGENCY_ROOM_REFERRAL_ROUTE,
    EMERGENCY_ROOM_PATIENT_CHECKUP_ROUTE,
    EMERGENCY_ROOM_PATIENT_CHECKUP_EDIT_ROUTE,
    REDIRECT_ROUTE,
    STATISTIC_DASHBOARD_ROUTE,
    ICON_LIST_ROUTE,
    ANALYSIS_LIST_ROUTE,
    DOCUMENT_ROUTE,
    MEDICAL_CASE_ROUTE,
    UNSCHEDULED_MEDICAL_CASE_ROUTE,
    EMERGENCY_ROOM_WITHOUT_REFERRAL_ROUTE,
    ATTACHED_POPULATION_ROUTE,
    ANALYTIC_BY_APPOINTMENTS_ROUTE,
    SCREENINGS_CVD_ROUTE,
    SCREENINGS_ENDOCRINE_ROUTE,
    REPORTS_ON_FORMS_ROUTE,
    EDIT_EPICRISIS_ROUTE,
    CREATE_EPICRISIS_ROUTE,
    COVER_SHEET_FORM003_ROUTE,
    RECIPE_LIST_ROUTE,
    ROLE_SELECTION_ROUTE,
    EXAMINATION_PLAN_ROUTE,
    REIMBURSMENT_RECIPE_LIST_ROUTE,
    STATISTICAL_MAP_FORM066_ROUTE,
    SURGERY_EDIT_ROUTE,
    ACCOUNTING_REFERRAL_ACCRUAL_LIST_ROUTE,
    ACCOUNTING_REFERRAL_SOURCE_LIST_ROUTE,
    REFERRAL_COMMISSION_LIST_ROUTE,
    OAUTH_ROUTE,
    BIRTH_PROTOCOL_ROUTE,
    REDIRECT_TO_BI_ROUTE,
    EMERGENCY_ROOM_EXCHANGE_CARD_FORM113_ROUTE,
    MEDICAL_CASE_EXCHANGE_CARD_FORM113_ROUTE,
    MEDICAL_CASE_EXCHANGE_CARD_FORM113_EDIT_ROUTE,
    UNSCHEDULED_MEDICAL_CASE_EXCHANGE_CARD_FORM113_ROUTE,
    UNSCHEDULED_MEDICAL_CASE_EXCHANGE_CARD_FORM113_EDIT_ROUTE,

    ..._setComponentInRoutesByViewsFolder({
      routes: [
        ..._getRoutes(),

        {
          path: '/:pathMatch(.*)*',
          redirect: ROUTE_PATH.notFound,
        },
      ],
    }),
  ],
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, _from, next) => {
  if (to.meta.title) {
    document.title = I18nService.t(to.meta.title as string);
  } else {
    document.title = 'DMED';
  }
  next();
});
router.afterEach((to, from, failure) => {
  if (!failure && to.path !== from.path) {
    amplitudeService.logPageVisit(to);
  }
});
// Assets

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _redirectCurrentPageByUserRole(_to: any, _from: any, next: (arg: string) => any) {
  const { userRole } = useSessionStore();
  const { featureFlags } = storeToRefs(useAppConfigStore());
  const isB2gInterface = computed(() => featureFlags.value?.['user.interface'] === 'b2g');

  switch (userRole) {
    case 'manager':
      return isB2gInterface.value
        ? next(ROUTE_PATH.patients.list)
        : next(ROUTE_PATH.contingent.active);
    case 'doctor':
    case 'nurse':
      return isB2gInterface.value
        ? next(ROUTE_PATH.patients.list)
        : next(ROUTE_PATH.contingent.active);
    case 'director':
      return isB2gInterface.value
        ? next(ROUTE_PATH.analyticByAppointments)
        : next(ROUTE_PATH.healthGroup.mainList);
    case 'laboratory':
      return next(ROUTE_PATH.laboratory.orders);
    case 'warehouse':
      return next(ROUTE_PATH.warehouse.nomenclature);
    case 'pharmacist':
      return next(ROUTE_PATH.pharmacy);
    case 'minister':
      return next(ROUTE_PATH.womenDashboard);
    case 'accountant':
      return next(ROUTE_PATH.cashRegister.invoices);
    case 'prescription-reviewer':
      return next(ROUTE_PATH.reimbursement.recipeList);
    case 'district-health-department':
    case 'city-health-department':
    case 'ministry-health-department':
      return next(ROUTE_PATH.redirectToBI);
    default:
      return next(ROUTE_PATH.noAccess);
  }
}

function _getRoutes() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routesModules = import.meta.glob<true, string, any>('@/router/**/*.routes.js', {
    eager: true,
  });
  return Object.keys(routesModules).reduce(
    (acc, key) => [...acc, ...routesModules[key].routes],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [] as any[]
  );
}

function _setComponentInRoutesByViewsFolder({
  routes,
}: {
  routes: Array<RouteRecordRaw & { childrenMap: Record<string, RouteRecordRaw> }>;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const views = import.meta.glob<true, string, any>('@/views/**/*.vue', { eager: true });
  const components: Record<string, RouteComponent> = {};

  for (const key in views) {
    const component = views[key].default;
    components[component.name] = component;
  }

  return insertComponentsInRoutes(routes, components);
}
